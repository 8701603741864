import React from "react"
import { Col, Container, Row } from "react-bootstrap"

function ContactForm() {

  return (
    <Container className="text-center">
      <Row className="global-padding">
        <Col md={{ span: 8, offset: 2 }}>
          <h4>Get in touch</h4>
          <p>Nothing is set in stone, let us know if you have any additional requirements. We are very flexible!</p>

          <form name="Contact Form" method="POST" data-netlify="true" className="text-center" action="/thanks" netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="Contact Form" />
            <p className="restrict">
              <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
            </p>

            <div>
              <input type="text" name="name" className="form-control" placeholder="Your Name" required />
            </div>
            <div>
              <input type="email" name="email" className="form-control" placeholder="Your Email" required />
            </div>
            <div>
              <input type="tel" name="telephone" className="form-control" placeholder="Your Phone Number" />
            </div>
            <div>
              <textarea name="message" className="form-control" rows="8" placeholder="Your Message" required />
            </div>
            <div>
              <input type="number" name="no_guests" className="form-control" placeholder="Approx Number of Guests" required />
            </div>
            <div>
              <input type="text" name="location" className="form-control" placeholder="Location of Event" required />
            </div>

            <button type="submit" className="btn btn-primary">Send</button>
          </form>

        </Col>
      </Row>
    </Container>
);
}

export default React.memo(ContactForm)