import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { FaFacebookF, FaInstagram } from 'react-icons/fa';

const Footer = () => (
  <footer>
    <Container className="text-center">
      <Row>
        <Col md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} xl={{ span: 6, offset: 3 }}>
          <h4>Flame &amp; Hog</h4>
          <p>
            At Flame and Hog we specialise in barbeque's, hog roasts and lamb roasts for small private parties, weddings, corporate events (up to 500 people) and just about any other type of function you can think of. No event is too big or too small.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 4 }} xl={{ span: 4, offset: 4 }} className="social-links">
          <a href="https://www.facebook.com/FlameAndHog" rel="noreferrer noopener" target="_blank" className="social-icon-button facebook">
            <FaFacebookF/>
          </a>
          <a href="https://www.instagram.com/flameandhog1/" rel="noreferrer noopener" target="_blank" className="social-icon-button instagram">
            <FaInstagram/>
          </a>
        </Col>
        <Col md={{ span: 4, offset: 4 }} lg={{ span: 4, offset: 4 }} xl={{ span: 4, offset: 4 }} className="legals">
          <span>
            Copyright © {new Date().getFullYear()} Flame & Hog
          </span>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer
