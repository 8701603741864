import React from "react"
import { Link } from "gatsby"

import { Navbar, Nav, Container} from "react-bootstrap"
import Logo from "../images/flame_hog_logo.png";

const CustomNavbar = ({ pageInfo }) => {
  console.log(pageInfo);
  return (
    <>
      <Navbar variant="dark" expand="lg" id="site-navbar">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="flex-1">
            <Nav className="mr-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/" className="link-no-style">
                <Nav.Link as="span" eventKey="index">
                  Home
                </Nav.Link>
              </Link>
              <Link to="/about" className="link-no-style">
                <Nav.Link as="span" eventKey="about">
                  About Us
                </Nav.Link>
              </Link>
              <Link to="/events" className="link-no-style">
                <Nav.Link as="span" eventKey="events">
                  Events
                </Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>

          <div className="order-first order-lg-0 d-flex justify-content-center">
            <a className="navbar-brand mx-0" href="/">
              <img src={Logo} alt="Flame & Hog" />
            </a>
          </div>

          <Navbar.Collapse className="flex-1 justify-content-center">
            <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
              <Link to="/menus" className="link-no-style">
                <Nav.Link as="span" eventKey="menus">
                  Menus
                </Nav.Link>
              </Link>
              <Link to="/gallery" className="link-no-style">
                <Nav.Link as="span" eventKey="gallery">
                  Gallery
                </Nav.Link>
              </Link>
              <Link to="/contact" className="link-no-style">
                <Nav.Link as="span" eventKey="contact">
                  Contact
                </Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>



        </Container>
      </Navbar>
    </>
  )
}

export default CustomNavbar
