import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const options = {
  streetViewControl: false,
  draggable: false,
  fullscreenControl: false,
}

function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBfPCnzRG2pe7xw6zx6wYt4j86tE_1FZkE"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: 53.2995201,
        lng: -3.0827432999999473
      }}
      zoom={11}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={options}
    >
      <Marker position={{
        lat: 53.2995201,
        lng: -3.0827432999999473
      }}  />
      { /* Child components, such as markers, info windows, etc. */ }
      <></>
    </GoogleMap>
  ) : <></>
}

export default React.memo(Map)
