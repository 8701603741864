import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Row, Col } from "react-bootstrap"

import Navbar from "./navBar"
import Footer from "./footer"
import Map from "./map"
import ContactForm from "./contactForm"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }

  ]
};

const Layout = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
        slide1: file(relativePath: { eq: "slides/1.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide2: file(relativePath: { eq: "slides/2.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide3: file(relativePath: { eq: "slides/3.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide4: file(relativePath: { eq: "slides/4.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide5: file(relativePath: { eq: "slides/5.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide6: file(relativePath: { eq: "slides/6.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide7: file(relativePath: { eq: "slides/7.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide8: file(relativePath: { eq: "slides/8.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        slide9: file(relativePath: { eq: "slides/9.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0 main">
          <Navbar pageInfo={pageInfo} />
        </Container>
          <main>

            {children}

            <div className="white-bg">
              <Container className="text-center">
                <Row className="global-padding">
                  <Col md={{ span: 12, offset: 0 }}>
                    <Slider {...settings} className="overflow-hidden image-slider">
                      <div className="slide"><Img fluid={data.slide1.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide2.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide3.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide4.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide5.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide6.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide7.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide8.childImageSharp.fluid} /></div>
                      <div className="slide"><Img fluid={data.slide9.childImageSharp.fluid} /></div>
                    </Slider>
                  </Col>
                </Row>
              </Container>
            </div>

            <div className="grey-bg">
              <ContactForm />
            </div>

            <div className="accent-bg">
              <Container className="text-center">
                <Row className="global-padding">
                  <Col md={{ span: 8, offset: 2 }}>
                    <h3>
                      Event Enquires Call - Call Tim on 07771 577462 or Rachel on 07734 032193
                    </h3>
                    <p>
                      We cater for small private parties, weddings, corporate events call us today to find out more....
                    </p>
                  </Col>
                </Row>
              </Container>
            </div>

            <Map />

          </main>
        <Footer/>

      </>
    )}
  />
)

export default Layout
